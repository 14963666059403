import React from 'react'
// import Image from 'next/image'
import styles from './componentstyles/Footer.module.css';
// import LinkedInIcon from '@mui/material/Icon';
// import linkedinlogo from ".assets/linkedin.png";
// import facebooklogo from ".assets/facebook.png";
// import instagramlogo from ".assets/Instagram-Logo.png";
// import twitterlogo from ".assets/Twitter-logo.png";
// import youtubelogo from ".assets/youtube-logo.png";

export default function Footer() {
  return (
    <>
    <div className={styles.Footer}>
    <h1 className={styles.hashthreedverse}>#Valpheta</h1>
    <h1 className={styles.born}>#BORN IN INDIA #BORN FOR THIS WORLD</h1>
    <h1 className={styles.followuson}>#Follow US On </h1>
    <div className={styles.social_media_div}>
    <div className={styles.row}>
  <div className={styles.column}>
  <a href="https://www.linkedin.com/company/threed-verse/" target="_blank" rel="noopener noreferrer">
  {/* <Image src="/assets/linkedin.png" alt="Vercel Logo"
    height={40}
    width={40} 
    className={styles.linkedinlogo}
      /> */}
      <img 
      // src={linkedinlogo} 
      src="./assets/linkedin.png"
      height={40} width={40} className={styles.linkedinlogo}></img>
   </a> 
  </div>
  <div className={styles.column}>
  <a href="https://www.instagram.com/threedversetverse/" target="_blank" rel="noopener noreferrer">
  {/* <Image src="/assets/Instagram-Logo.png" alt="Vercel Logo"
    height={50}
    width={70} 
    className={styles.instagramlogo}
      /> */}
      <img 
      // src={instagramlogo}  
      src="./assets/Instagram-Logo.png"
      height={50}
    width={70} 
    className={styles.instagramlogo} ></img>
   </a> 
  </div>
  <div className={styles.column}>
  <a href="https://twitter.com/Threed_Verse" target="_blank" rel="noopener noreferrer">
  {/* <Image src="/assets/Twitter-logo.png" alt="Vercel Logo"
    height={40}
    width={40} 
    className={styles.twitterlogo}
      /> */}
       <img
        // src={twitterlogo}  
        src="assets/Twitter-logo.png"
        height={40}
    width={40} 
    className={styles.twitterlogo}></img>
   </a> 
  </div>
  <div className={styles.column}>
  <a href="https://www.facebook.com/profile.php?id=100090795979060" target="_blank" rel="noopener noreferrer">
  {/* <Image src="/assets/Facebook-logo.png" alt="Vercel Logo"
    height={50}
    width={60} 
    className={styles.facebooklogo}
      /> */}
       <img 
      //  src={facebooklogo}  
      src="assets/Facebook-logo.png"
       height={50}
    width={60} 
    className={styles.facebooklogo}></img>
   </a> 
  </div>
  <div className={styles.column}>
  <a href="https://www.youtube.com/@threedverserevolutuon" target="_blank" rel="noopener noreferrer">
  {/* <Image src="/assets/youtube-logo.png" alt="Vercel Logo"
   height={50}
   width={50} 
    className={styles.youtubelogo}
      /> */}
       <img 
      //  src={youtubelogo}   
      src="assets/youtube-logo.png" 
       height={50}
   width={50} 
    className={styles.youtubelogo}></img>
   </a> 
  </div>
</div>
    </div>
    </div>
    </>
  )
}
