import React, { useEffect,useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

// export function Steering(props) {
//   const group = useRef();
//   const { nodes, materials, animations } = useGLTF("/assets/Steering.glb");
//   const { actions } = useAnimations(animations, group);
//   useEffect(()=>{
//     console.log(actions)
//     actions.SteerRotate.play();
//   })
//   return (
//     <group ref={group} {...props} dispose={null}>
//       <group name="Scene">
//         <group name="Empty" rotation={[0, Math.PI / 2, 0]} />
//         <group
//           name="SteerRotate"
//           position={[-0.09, 1.57, 1.39]}
//           rotation={[Math.PI / 2, 0, 0]}
//           scale={8.95}
//         >
//           <mesh
//             name="Curve036"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036.geometry}
//             material={materials["SVGMat.037"]}
//           />
//           <mesh
//             name="Curve036_1"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_1.geometry}
//             material={materials["SVGMat.002"]}
//           />
//           <mesh
//             name="Curve036_2"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_2.geometry}
//             material={materials["SVGMat.003"]}
//           />
//           <mesh
//             name="Curve036_3"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_3.geometry}
//             material={materials["SVGMat.004"]}
//           />
//           <mesh
//             name="Curve036_4"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_4.geometry}
//             material={materials["SVGMat.005"]}
//           />
//           <mesh
//             name="Curve036_5"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_5.geometry}
//             material={materials["SVGMat.006"]}
//           />
//           <mesh
//             name="Curve036_6"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_6.geometry}
//             material={materials["SVGMat.007"]}
//           />
//           <mesh
//             name="Curve036_7"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_7.geometry}
//             material={materials["SVGMat.008"]}
//           />
//           <mesh
//             name="Curve036_8"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_8.geometry}
//             material={materials["SVGMat.009"]}
//           />
//           <mesh
//             name="Curve036_9"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_9.geometry}
//             material={materials["SVGMat.010"]}
//           />
//           <mesh
//             name="Curve036_10"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_10.geometry}
//             material={materials["SVGMat.011"]}
//           />
//           <mesh
//             name="Curve036_11"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_11.geometry}
//             material={materials["SVGMat.012"]}
//           />
//           <mesh
//             name="Curve036_12"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_12.geometry}
//             material={materials["SVGMat.013"]}
//           />
//           <mesh
//             name="Curve036_13"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_13.geometry}
//             material={materials["SVGMat.014"]}
//           />
//           <mesh
//             name="Curve036_14"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_14.geometry}
//             material={materials["SVGMat.015"]}
//           />
//           <mesh
//             name="Curve036_15"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_15.geometry}
//             material={materials["SVGMat.016"]}
//           />
//           <mesh
//             name="Curve036_16"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_16.geometry}
//             material={materials["SVGMat.017"]}
//           />
//           <mesh
//             name="Curve036_17"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_17.geometry}
//             material={materials["SVGMat.018"]}
//           />
//           <mesh
//             name="Curve036_18"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_18.geometry}
//             material={materials["SVGMat.019"]}
//           />
//           <mesh
//             name="Curve036_19"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_19.geometry}
//             material={materials["SVGMat.020"]}
//           />
//           <mesh
//             name="Curve036_20"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_20.geometry}
//             material={materials["SVGMat.021"]}
//           />
//           <mesh
//             name="Curve036_21"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_21.geometry}
//             material={materials["SVGMat.022"]}
//           />
//           <mesh
//             name="Curve036_22"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_22.geometry}
//             material={materials["SVGMat.023"]}
//           />
//           <mesh
//             name="Curve036_23"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_23.geometry}
//             material={materials["SVGMat.024"]}
//           />
//           <mesh
//             name="Curve036_24"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_24.geometry}
//             material={materials["SVGMat.025"]}
//           />
//           <mesh
//             name="Curve036_25"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_25.geometry}
//             material={materials["SVGMat.026"]}
//           />
//           <mesh
//             name="Curve036_26"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_26.geometry}
//             material={materials["SVGMat.027"]}
//           />
//           <mesh
//             name="Curve036_27"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_27.geometry}
//             material={materials["SVGMat.028"]}
//           />
//           <mesh
//             name="Curve036_28"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_28.geometry}
//             material={materials["SVGMat.029"]}
//           />
//           <mesh
//             name="Curve036_29"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_29.geometry}
//             material={materials["SVGMat.030"]}
//           />
//           <mesh
//             name="Curve036_30"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_30.geometry}
//             material={materials["SVGMat.031"]}
//           />
//           <mesh
//             name="Curve036_31"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_31.geometry}
//             material={materials["SVGMat.032"]}
//           />
//           <mesh
//             name="Curve036_32"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_32.geometry}
//             material={materials["SVGMat.033"]}
//           />
//           <mesh
//             name="Curve036_33"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_33.geometry}
//             material={materials["SVGMat.034"]}
//           />
//           <mesh
//             name="Curve036_34"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_34.geometry}
//             material={materials["SVGMat.035"]}
//           />
//           <mesh
//             name="Curve036_35"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_35.geometry}
//             material={materials["SVGMat.036"]}
//           />
//           <mesh
//             name="Curve036_36"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_36.geometry}
//             material={materials["SVGMat.038"]}
//           />
//           <mesh
//             name="Curve036_37"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_37.geometry}
//             material={materials["SVGMat.039"]}
//           />
//           <mesh
//             name="Curve036_38"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_38.geometry}
//             material={materials["SVGMat.040"]}
//           />
//           <mesh
//             name="Curve036_39"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_39.geometry}
//             material={materials["SVGMat.041"]}
//           />
//           <mesh
//             name="Curve036_40"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_40.geometry}
//             material={materials["SVGMat.042"]}
//           />
//           <mesh
//             name="Curve036_41"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_41.geometry}
//             material={materials["SVGMat.043"]}
//           />
//           <mesh
//             name="Curve036_42"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_42.geometry}
//             material={materials["SVGMat.044"]}
//           />
//           <mesh
//             name="Curve036_43"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_43.geometry}
//             material={materials["SVGMat.045"]}
//           />
//           <mesh
//             name="Curve036_44"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_44.geometry}
//             material={materials["SVGMat.046"]}
//           />
//           <mesh
//             name="Curve036_45"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_45.geometry}
//             material={materials["SVGMat.047"]}
//           />
//           <mesh
//             name="Curve036_46"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_46.geometry}
//             material={materials["SVGMat.048"]}
//           />
//           <mesh
//             name="Curve036_47"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_47.geometry}
//             material={materials["SVGMat.049"]}
//           />
//           <mesh
//             name="Curve036_48"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_48.geometry}
//             material={materials["SVGMat.050"]}
//           />
//           <mesh
//             name="Curve036_49"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_49.geometry}
//             material={materials["SVGMat.051"]}
//           />
//           <mesh
//             name="Curve036_50"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_50.geometry}
//             material={materials["SVGMat.052"]}
//           />
//           <mesh
//             name="Curve036_51"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_51.geometry}
//             material={materials["SVGMat.053"]}
//           />
//           <mesh
//             name="Curve036_52"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_52.geometry}
//             material={materials["SVGMat.054"]}
//           />
//           <mesh
//             name="Curve036_53"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_53.geometry}
//             material={materials["SVGMat.055"]}
//           />
//           <mesh
//             name="Curve036_54"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_54.geometry}
//             material={materials["SVGMat.056"]}
//           />
//           <mesh
//             name="Curve036_55"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_55.geometry}
//             material={materials["SVGMat.057"]}
//           />
//           <mesh
//             name="Curve036_56"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_56.geometry}
//             material={materials["SVGMat.058"]}
//           />
//           <mesh
//             name="Curve036_57"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_57.geometry}
//             material={materials["SVGMat.059"]}
//           />
//           <mesh
//             name="Curve036_58"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_58.geometry}
//             material={materials["SVGMat.060"]}
//           />
//           <mesh
//             name="Curve036_59"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_59.geometry}
//             material={materials["SVGMat.061"]}
//           />
//           <mesh
//             name="Curve036_60"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_60.geometry}
//             material={materials["SVGMat.062"]}
//           />
//           <mesh
//             name="Curve036_61"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_61.geometry}
//             material={materials["SVGMat.063"]}
//           />
//           <mesh
//             name="Curve036_62"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_62.geometry}
//             material={materials["SVGMat.064"]}
//           />
//           <mesh
//             name="Curve036_63"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_63.geometry}
//             material={materials["SVGMat.065"]}
//           />
//           <mesh
//             name="Curve036_64"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_64.geometry}
//             material={materials["SVGMat.066"]}
//           />
//           <mesh
//             name="Curve036_65"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_65.geometry}
//             material={materials["Material.006"]}
//           />
//           <mesh
//             name="Curve036_66"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_66.geometry}
//             material={materials.Material}
//           />
//           <mesh
//             name="Curve036_67"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_67.geometry}
//             material={materials["Material.004"]}
//           />
//           <mesh
//             name="Curve036_68"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_68.geometry}
//             material={materials["Material.001"]}
//           />
//           <mesh
//             name="Curve036_69"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_69.geometry}
//             material={materials["Material.002"]}
//           />
//           <mesh
//             name="Curve036_70"
//             castShadow
//             receiveShadow
//             geometry={nodes.Curve036_70.geometry}
//             material={materials["Material.003"]}
//           />
//         </group>
//         <mesh
//           name="Plane001"
//           castShadow
//           receiveShadow
//           geometry={nodes.Plane001.geometry}
//           material={nodes.Plane001.material}
//           position={[0, -1.22, 0]}
//           scale={0.46}
//         />
//         <mesh
//           name="Cylinder"
//           castShadow
//           receiveShadow
//           geometry={nodes.Cylinder.geometry}
//           material={nodes.Cylinder.material}
//           position={[0.3, 0.33, 0]}
//           scale={0.01}
//         />
//       </group>
//     </group>
//   );
// }

// useGLTF.preload("/assets/Steering.glb");


export function Steering(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/assets/Steering.glb");
  const { actions } = useAnimations(animations, group);
    useEffect(()=>{
    console.log(actions)
    actions.SteerRotate.play();
  })
  return (
        <group ref={group} {...props} dispose={null}>
          <group name="Scene">
            <group
              name="SteerRotate"
              position={[-0.09, 1.57, 0.28]}
              rotation={[Math.PI / 2, 0, 0]}
              scale={8.95}
            >
              <mesh
                name="Curve036"
                castShadow
                receiveShadow
                geometry={nodes.Curve036.geometry}
                material={materials["SVGMat.037"]}
              />
              <mesh
                name="Curve036_1"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_1.geometry}
                material={materials["SVGMat.002"]}
              />
              <mesh
                name="Curve036_2"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_2.geometry}
                material={materials["SVGMat.003"]}
              />
              <mesh
                name="Curve036_3"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_3.geometry}
                material={materials["SVGMat.004"]}
              />
              <mesh
                name="Curve036_4"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_4.geometry}
                material={materials["SVGMat.005"]}
              />
              <mesh
                name="Curve036_5"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_5.geometry}
                material={materials["SVGMat.006"]}
              />
              <mesh
                name="Curve036_6"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_6.geometry}
                material={materials["SVGMat.007"]}
              />
              <mesh
                name="Curve036_7"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_7.geometry}
                material={materials["SVGMat.008"]}
              />
              <mesh
                name="Curve036_8"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_8.geometry}
                material={materials["SVGMat.009"]}
              />
              <mesh
                name="Curve036_9"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_9.geometry}
                material={materials["SVGMat.010"]}
              />
              <mesh
                name="Curve036_10"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_10.geometry}
                material={materials["SVGMat.011"]}
              />
              <mesh
                name="Curve036_11"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_11.geometry}
                material={materials["SVGMat.012"]}
              />
              <mesh
                name="Curve036_12"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_12.geometry}
                material={materials["SVGMat.013"]}
              />
              <mesh
                name="Curve036_13"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_13.geometry}
                material={materials["SVGMat.014"]}
              />
              <mesh
                name="Curve036_14"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_14.geometry}
                material={materials["SVGMat.015"]}
              />
              <mesh
                name="Curve036_15"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_15.geometry}
                material={materials["SVGMat.016"]}
              />
              <mesh
                name="Curve036_16"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_16.geometry}
                material={materials["SVGMat.017"]}
              />
              <mesh
                name="Curve036_17"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_17.geometry}
                material={materials["SVGMat.018"]}
              />
              <mesh
                name="Curve036_18"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_18.geometry}
                material={materials["SVGMat.019"]}
              />
              <mesh
                name="Curve036_19"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_19.geometry}
                material={materials["SVGMat.020"]}
              />
              <mesh
                name="Curve036_20"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_20.geometry}
                material={materials["SVGMat.021"]}
              />
              <mesh
                name="Curve036_21"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_21.geometry}
                material={materials["SVGMat.022"]}
              />
              <mesh
                name="Curve036_22"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_22.geometry}
                material={materials["SVGMat.023"]}
              />
              <mesh
                name="Curve036_23"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_23.geometry}
                material={materials["SVGMat.024"]}
              />
              <mesh
                name="Curve036_24"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_24.geometry}
                material={materials["SVGMat.025"]}
              />
              <mesh
                name="Curve036_25"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_25.geometry}
                material={materials["SVGMat.026"]}
              />
              <mesh
                name="Curve036_26"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_26.geometry}
                material={materials["SVGMat.027"]}
              />
              <mesh
                name="Curve036_27"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_27.geometry}
                material={materials["SVGMat.028"]}
              />
              <mesh
                name="Curve036_28"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_28.geometry}
                material={materials["SVGMat.029"]}
              />
              <mesh
                name="Curve036_29"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_29.geometry}
                material={materials["SVGMat.030"]}
              />
              <mesh
                name="Curve036_30"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_30.geometry}
                material={materials["SVGMat.031"]}
              />
              <mesh
                name="Curve036_31"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_31.geometry}
                material={materials["SVGMat.032"]}
              />
              <mesh
                name="Curve036_32"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_32.geometry}
                material={materials["SVGMat.033"]}
              />
              <mesh
                name="Curve036_33"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_33.geometry}
                material={materials["SVGMat.034"]}
              />
              <mesh
                name="Curve036_34"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_34.geometry}
                material={materials["SVGMat.035"]}
              />
              <mesh
                name="Curve036_35"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_35.geometry}
                material={materials["SVGMat.036"]}
              />
              <mesh
                name="Curve036_36"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_36.geometry}
                material={materials["SVGMat.038"]}
              />
              <mesh
                name="Curve036_37"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_37.geometry}
                material={materials["SVGMat.039"]}
              />
              <mesh
                name="Curve036_38"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_38.geometry}
                material={materials["SVGMat.040"]}
              />
              <mesh
                name="Curve036_39"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_39.geometry}
                material={materials["SVGMat.041"]}
              />
              <mesh
                name="Curve036_40"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_40.geometry}
                material={materials["SVGMat.042"]}
              />
              <mesh
                name="Curve036_41"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_41.geometry}
                material={materials["SVGMat.043"]}
              />
              <mesh
                name="Curve036_42"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_42.geometry}
                material={materials["SVGMat.044"]}
              />
              <mesh
                name="Curve036_43"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_43.geometry}
                material={materials["SVGMat.045"]}
              />
              <mesh
                name="Curve036_44"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_44.geometry}
                material={materials["SVGMat.046"]}
              />
              <mesh
                name="Curve036_45"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_45.geometry}
                material={materials["SVGMat.047"]}
              />
              <mesh
                name="Curve036_46"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_46.geometry}
                material={materials["SVGMat.048"]}
              />
              <mesh
                name="Curve036_47"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_47.geometry}
                material={materials["SVGMat.049"]}
              />
              <mesh
                name="Curve036_48"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_48.geometry}
                material={materials["SVGMat.050"]}
              />
              <mesh
                name="Curve036_49"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_49.geometry}
                material={materials["SVGMat.051"]}
              />
              <mesh
                name="Curve036_50"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_50.geometry}
                material={materials["SVGMat.052"]}
              />
              <mesh
                name="Curve036_51"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_51.geometry}
                material={materials["SVGMat.053"]}
              />
              <mesh
                name="Curve036_52"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_52.geometry}
                material={materials["SVGMat.054"]}
              />
              <mesh
                name="Curve036_53"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_53.geometry}
                material={materials["SVGMat.055"]}
              />
              <mesh
                name="Curve036_54"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_54.geometry}
                material={materials["SVGMat.056"]}
              />
              <mesh
                name="Curve036_55"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_55.geometry}
                material={materials["SVGMat.057"]}
              />
              <mesh
                name="Curve036_56"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_56.geometry}
                material={materials["SVGMat.058"]}
              />
              <mesh
                name="Curve036_57"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_57.geometry}
                material={materials["SVGMat.059"]}
              />
              <mesh
                name="Curve036_58"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_58.geometry}
                material={materials["SVGMat.060"]}
              />
              <mesh
                name="Curve036_59"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_59.geometry}
                material={materials["SVGMat.061"]}
              />
              <mesh
                name="Curve036_60"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_60.geometry}
                material={materials["SVGMat.062"]}
              />
              <mesh
                name="Curve036_61"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_61.geometry}
                material={materials["SVGMat.063"]}
              />
              <mesh
                name="Curve036_62"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_62.geometry}
                material={materials["SVGMat.064"]}
              />
              <mesh
                name="Curve036_63"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_63.geometry}
                material={materials["SVGMat.065"]}
              />
              <mesh
                name="Curve036_64"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_64.geometry}
                material={materials["SVGMat.066"]}
              />
              <mesh
                name="Curve036_65"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_65.geometry}
                material={materials["Material.006"]}
              />
              <mesh
                name="Curve036_66"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_66.geometry}
                material={materials.Material}
              />
              <mesh
                name="Curve036_67"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_67.geometry}
                material={materials["Material.004"]}
              />
              <mesh
                name="Curve036_68"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_68.geometry}
                material={materials["Material.001"]}
              />
              <mesh
                name="Curve036_69"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_69.geometry}
                material={materials["Material.002"]}
              />
              <mesh
                name="Curve036_70"
                castShadow
                receiveShadow
                geometry={nodes.Curve036_70.geometry}
                material={materials["Material.003"]}
              />
            </group>
          </group>
        </group>
  );
}

useGLTF.preload("/asstes/Steering.glb");