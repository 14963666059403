import React from 'react'
import styles from './componentstyles/OurBelief.module.css'

export default function Sustainable() {
  return (
    <div className={styles.aboutusscreencontainer}>
    <h1 className={styles.newourbelief}>Sustainable</h1>
    <h1 className={styles.attv}>We ❤️ Sustainable Technology</h1>
    <h1 className={styles.attv}>We ❤️ Revolution</h1>
    <h1 className={styles.attv}>We ❤️ Ev</h1>
    <div className={styles.battery}></div>
    </div>
  )
}
