import React from 'react'
// import Image from 'next/image'
import styles from './componentstyles/Hero.module.css'
import { OrbitControls } from '@react-three/drei'
import { lazy,Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
// import { Environment } from '@react-three/drei'
// import { ThreedVerseLogo } from './THREEDCOMPONENTS/ThreedVerseLogo'
import { ValphetaLogo }  from './THREEDCOMPONENTS/ValphetaLogo'
import { ScifiVehicle }  from './THREEDCOMPONENTS/ScifiVehicle'
// import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

// const SplittedThreedVerseLogo = lazy(() => import("./THREEDCOMPONENTS/SplittedThreedVerseLogo"));
// const ValphetaLogo = lazy(() => import("./THREEDCOMPONENTS/ValphetaLogo"));

// export default function Hero() {
//   return (
//     <>
//     <div className={styles.herocontainer}>
//         <h1 className={styles.welcometo}>Welcome To</h1>
//         <div className={styles.tvlogocontainer}>
//         <Image src="/assets/large_black_tverse.png" alt="Vercel Logo"
//     height={200}
// width={600} 
//     className={styles.tvlogo}
//       />
//        {/* <h1 className={styles.welcometo}>We are</h1>
//        <h1 className={styles.animate_character}>Building The Next Big Thing!</h1> */}
//        <h1 className={styles.animate_character}>We are Building The Next Big Thing!</h1>
//         </div>
//     </div>
//     </>
//   )
// }


export default function Hero() {
  return (
    <>
    <div className={styles.herocontainer}>
        {/* <h1 className={styles.welcometo}>Welcome To</h1> */}
        <h1 className={styles.valphetatitle}>Valpheta</h1>
        <div className={styles.threedtlogocontainer}>
          {/* <Canvas style={{backgroundColor:"black"}}> */}
          {/* <Canvas style={{width: "100vw", height: "50vh" ,backgroundColor:"black" }}> */}
          <Canvas style={{width: "100vw", height: "50vh"}}>
        <ambientLight intensity={0.2} />
  <directionalLight color="white" position={[1, 1, 1]} />
        <Suspense fallback={null}>
          {/* <ThreedVerseLogo /> */}
          {/* <SplittedThreedVerseLogo/> */}
          {/* <ValphetaLogo/> */}
          <ScifiVehicle/>
          <OrbitControls/>
          {/* <Environment preset="sunset" background /> */}
        </Suspense>
      </Canvas>
      <div className={styles.tvlogocontainer}>
        <h1 className={styles.welcometo}>We are</h1>
<h1 className={styles.animate_character}>Revolutionizing Mobility!</h1> 
        </div>
    </div>
        </div>
       
    </>
  )
}
