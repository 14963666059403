import React from 'react'
import styles from './componentstyles/OurBelief.module.css'
// import styles from '../../styles/Hscreen.module.css'

export default function BeyondLevelFive() {
  return (
    <>
        <div className={styles.aboutusscreencontainer}>
    <h1 className={styles.newourbelief}>Beyond Level 5</h1>
    <h1 className={styles.attv}>Let's Go Beyond Level 5</h1>
    <h1 className={styles.attv}>Let's Solve The Impossible</h1>
    {/* <h1 className={styles.empowering}>The Ai We are Building</h1>
    <h1 className={styles.ourbelief}>Powerful Ai with Unified Model Architecture</h1>
    <h1 className={styles.ourbelief}>Blazingly Fast 🔥</h1>
    <h1 className={styles.ourbelief}>MultiTask Capability</h1>
    <h1 className={styles.ourbelief}>Minimum Computing Requirements</h1>
    <h1 className={styles.ourbelief}>Every Environment Dominator</h1>
    <h1 className={styles.ourbelief}>Less amount of Training Time</h1>
    <h1 className={styles.attv}>We are Building Deep Tech that holds potential to revolutionize Mobility in this World</h1> */}
    </div>
    </>
  )
}