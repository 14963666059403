import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function ValphetaLogo(props) {
  const { nodes, materials } = useGLTF("/assets/ValphetaTestTwo.glb");
  return (
    <group {...props} dispose={null}>
      <group
        // position={[-4.83, 0, 0]}
        position={[-4.83, -1, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={14.16}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_1.geometry}
          material={materials["SVGMat.089"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_2.geometry}
          material={materials["SVGMat.060"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_3.geometry}
          material={materials["SVGMat.061"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_4.geometry}
          material={materials["SVGMat.062"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_5.geometry}
          material={materials["SVGMat.063"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_6.geometry}
          material={materials["SVGMat.064"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_7.geometry}
          material={materials["SVGMat.065"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_8.geometry}
          material={materials["SVGMat.066"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_9.geometry}
          material={materials["SVGMat.067"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_10.geometry}
          material={materials["SVGMat.068"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_11.geometry}
          material={materials["SVGMat.069"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_12.geometry}
          material={materials["SVGMat.070"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_13.geometry}
          material={materials["SVGMat.071"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_14.geometry}
          material={materials["SVGMat.072"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_15.geometry}
          material={materials["SVGMat.073"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_16.geometry}
          material={materials["SVGMat.074"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_17.geometry}
          material={materials["SVGMat.075"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_18.geometry}
          material={materials["SVGMat.076"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_19.geometry}
          material={materials["SVGMat.077"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_20.geometry}
          material={materials["SVGMat.078"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_21.geometry}
          material={materials["SVGMat.079"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_22.geometry}
          material={materials["SVGMat.080"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_23.geometry}
          material={materials["SVGMat.081"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_24.geometry}
          material={materials["SVGMat.082"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_25.geometry}
          material={materials["SVGMat.083"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_26.geometry}
          material={materials["SVGMat.084"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_27.geometry}
          material={materials["SVGMat.085"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_28.geometry}
          material={materials["SVGMat.086"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_29.geometry}
          material={materials["SVGMat.087"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_30.geometry}
          material={materials["SVGMat.088"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_31.geometry}
          material={materials["SVGMat.090"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_32.geometry}
          material={materials["SVGMat.091"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_33.geometry}
          material={materials["SVGMat.092"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_34.geometry}
          material={materials["SVGMat.093"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_35.geometry}
          material={materials["SVGMat.094"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_36.geometry}
          material={materials["SVGMat.095"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_37.geometry}
          material={materials["SVGMat.096"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_38.geometry}
          material={materials["SVGMat.097"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_39.geometry}
          material={materials["SVGMat.098"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_40.geometry}
          material={materials["SVGMat.099"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_41.geometry}
          material={materials["SVGMat.100"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_42.geometry}
          material={materials["SVGMat.101"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_43.geometry}
          material={materials["SVGMat.102"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_44.geometry}
          material={materials["SVGMat.103"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_45.geometry}
          material={materials["SVGMat.104"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_46.geometry}
          material={materials["SVGMat.105"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_47.geometry}
          material={materials["SVGMat.106"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_48.geometry}
          material={materials["SVGMat.107"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_49.geometry}
          material={materials["SVGMat.108"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_50.geometry}
          material={materials["SVGMat.109"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_51.geometry}
          material={materials["SVGMat.110"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_52.geometry}
          material={materials["SVGMat.111"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_53.geometry}
          material={materials["SVGMat.112"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_54.geometry}
          material={materials["SVGMat.113"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_55.geometry}
          material={materials["SVGMat.114"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_56.geometry}
          material={materials["SVGMat.115"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_57.geometry}
          material={materials["SVGMat.116"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_58.geometry}
          material={materials["SVGMat.117"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_59.geometry}
          material={materials["SVGMat.118"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_60.geometry}
          material={materials["SVGMat.119"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_61.geometry}
          material={materials["SVGMat.120"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_62.geometry}
          material={materials["SVGMat.121"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_63.geometry}
          material={materials["SVGMat.122"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_64.geometry}
          material={materials["SVGMat.123"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_65.geometry}
          material={materials["SVGMat.124"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_66.geometry}
          material={materials["SVGMat.125"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_67.geometry}
          material={materials["SVGMat.126"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_68.geometry}
          material={materials["SVGMat.127"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_69.geometry}
          material={materials["SVGMat.128"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_70.geometry}
          material={materials["SVGMat.129"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_71.geometry}
          material={materials["SVGMat.130"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_72.geometry}
          material={materials["SVGMat.131"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_73.geometry}
          material={materials["SVGMat.132"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_74.geometry}
          material={materials["SVGMat.133"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_75.geometry}
          material={materials["SVGMat.134"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_76.geometry}
          material={materials["SVGMat.135"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_77.geometry}
          material={materials["SVGMat.136"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_78.geometry}
          material={materials["SVGMat.137"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_79.geometry}
          material={materials["SVGMat.138"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_80.geometry}
          material={materials["SVGMat.139"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_81.geometry}
          material={materials["SVGMat.140"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_82.geometry}
          material={materials["SVGMat.141"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_83.geometry}
          material={materials["SVGMat.142"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_84.geometry}
          material={materials["SVGMat.143"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_85.geometry}
          material={materials["SVGMat.144"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_86.geometry}
          material={materials["SVGMat.145"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_87.geometry}
          material={materials["SVGMat.146"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_88.geometry}
          material={materials["SVGMat.147"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_89.geometry}
          material={materials["SVGMat.148"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_90.geometry}
          material={materials["SVGMat.149"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_91.geometry}
          material={materials["SVGMat.150"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_92.geometry}
          material={materials["SVGMat.151"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_93.geometry}
          material={materials["SVGMat.152"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_94.geometry}
          material={materials["SVGMat.156"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve031_95.geometry}
          material={materials["SVGMat.157"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/assets/ValphetaTestTwo.glb");