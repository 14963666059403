import React from 'react'
import styles from './componentstyles/OurBelief.module.css'
// import styles from '../../styles/Hscreen.module.css'

export default function OurBelief() {
  return (
    <>
        <div className={styles.aboutusscreencontainer}>
    <h1 className={styles.newourbelief}>Our Belief</h1>
    <h1 className={styles.attv}>&quot;The Most Dangerous Job a Person does everyday is driving & walking on roads&quot;</h1>
    <h1 className={styles.ourbelief}>We are Humans not machines we get distracted ,we are moody ,our minds wonder ,We fear about PAST,PRESENT,FUTURE ,We loose patience,We get Frustrated & We are not Perfect</h1>
    <h1 className={styles.empowering}>But Machines Are</h1>
    {/* <h1 className={styles.ourbelief}>They Work as needed as programmed,They have the potential to be Perfect</h1> */}
    <h1 className={styles.ourbelief}>They Work </h1>
    <h1 className={styles.ourbelief}>As needed </h1>
    <h1 className={styles.ourbelief}>As programmed</h1>
    <h1 className={styles.ourbelief}>They have the potential to be Perfect</h1>
    <h1 className={styles.attv}>&quot;So Why to take Risk For Yourself and For others? Let Them do their Job and you relax & enjoy the Life &quot;</h1>
    </div>
    </>
  )
}
