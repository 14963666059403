import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

// export function ScifiVehicle(props) {
//   const { nodes, materials } = useGLTF("/assets/sci fi vehicle 8.glb");
//   return (
//     <group {...props} dispose={null}>
//       <group
//         position={[3.64, 2.73, 0]}
//         rotation={[0, Math.PI / 2, 0]}
//         // scale={[5.62, 5.93, 6.82]}
//         scale={[1.62,1.62,1.62]}
//       >
//         <mesh
//           castShadow
//           receiveShadow
//           geometry={nodes.Cube019_1.geometry}
//           material={materials["body.006"]}
//         />
//         <mesh
//           castShadow
//           receiveShadow
//           geometry={nodes.Cube019_2.geometry}
//           material={materials["glass.007"]}
//         />
//         <mesh
//           castShadow
//           receiveShadow
//           geometry={nodes.Cube019_3.geometry}
//           material={materials["plastic.006"]}
//         />
//         <mesh
//           castShadow
//           receiveShadow
//           geometry={nodes.Cube019_4.geometry}
//           material={materials["chrome.003"]}
//         />
//         <mesh
//           castShadow
//           receiveShadow
//           geometry={nodes.Cube019_5.geometry}
//           material={materials["glow.002"]}
//         />
//         <mesh
//           castShadow
//           receiveShadow
//           geometry={nodes.Cube019_6.geometry}
//           material={materials["logo.002"]}
//         />
//         <mesh
//           castShadow
//           receiveShadow
//           geometry={nodes.Cube019_7.geometry}
//           material={materials["tire.001"]}
//         />
//       </group>
//     </group>
//   );
// }

// useGLTF.preload("/assets/sci fi vehicle 8.glb");


// import React, { useRef } from "react";
// import { useGLTF } from "@react-three/drei";

export function ScifiVehicle(props) {
  const { nodes, materials } = useGLTF("/assets/sci fi vehicle 8.glb");
  return (
    <group {...props} dispose={null}>
      <group
        // position={[0.57, 2.73, 0]}
        // position={[0,0, 0]} //works best
        // position={[0,-1.5, 0]}//works for us with logo
        position={[0,-0.8, 0]}
        // rotation={[Math.PI, 0, Math.PI]}
        //increment in points example 1.51, 1.52,1.53 or else it will elad to drastic changes
        rotation={[0,1.55,0]}
        // scale={[1.97, 2.08, 2.39]}
        scale={[0.5,0.8,0.5]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube019_1.geometry}
          material={materials["body.006"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube019_2.geometry}
          material={materials["glass.007"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube019_3.geometry}
          material={materials["plastic.006"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube019_4.geometry}
          material={materials["chrome.003"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube019_5.geometry}
          material={materials["glow.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube019_6.geometry}
          material={materials["logo.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube019_7.geometry}
          material={materials["tire.001"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/assets/sci fi vehicle 8.glb");