import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function ValphetaTestThree(props) {
  const { nodes, materials } = useGLTF("/assets/ValphetaTestThree.glb");
  return (
    <group {...props} dispose={null}>
      <group
        position={[-7.26, 0, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={58.37}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_1.geometry}
          material={materials["SVGMat.037"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_2.geometry}
          material={materials["SVGMat.002"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_3.geometry}
          material={materials["SVGMat.003"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_4.geometry}
          material={materials["SVGMat.004"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_5.geometry}
          material={materials["SVGMat.005"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_6.geometry}
          material={materials["SVGMat.006"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_7.geometry}
          material={materials["SVGMat.007"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_8.geometry}
          material={materials["SVGMat.008"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_9.geometry}
          material={materials["SVGMat.009"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_10.geometry}
          material={materials["SVGMat.010"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_11.geometry}
          material={materials["SVGMat.011"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_12.geometry}
          material={materials["SVGMat.012"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_13.geometry}
          material={materials["SVGMat.013"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_14.geometry}
          material={materials["SVGMat.014"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_15.geometry}
          material={materials["SVGMat.015"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_16.geometry}
          material={materials["SVGMat.016"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_17.geometry}
          material={materials["SVGMat.017"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_18.geometry}
          material={materials["SVGMat.018"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_19.geometry}
          material={materials["SVGMat.019"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_20.geometry}
          material={materials["SVGMat.020"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_21.geometry}
          material={materials["SVGMat.021"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_22.geometry}
          material={materials["SVGMat.022"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_23.geometry}
          material={materials["SVGMat.023"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_24.geometry}
          material={materials["SVGMat.024"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_25.geometry}
          material={materials["SVGMat.025"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_26.geometry}
          material={materials["SVGMat.026"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_27.geometry}
          material={materials["SVGMat.027"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_28.geometry}
          material={materials["SVGMat.028"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_29.geometry}
          material={materials["SVGMat.029"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_30.geometry}
          material={materials["SVGMat.030"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_31.geometry}
          material={materials["SVGMat.031"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_32.geometry}
          material={materials["SVGMat.032"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_33.geometry}
          material={materials["SVGMat.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_34.geometry}
          material={materials["SVGMat.034"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_35.geometry}
          material={materials["SVGMat.035"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_36.geometry}
          material={materials["SVGMat.036"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_37.geometry}
          material={materials["SVGMat.038"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_38.geometry}
          material={materials["SVGMat.039"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_39.geometry}
          material={materials["SVGMat.040"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_40.geometry}
          material={materials["SVGMat.041"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_41.geometry}
          material={materials["SVGMat.042"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_42.geometry}
          material={materials["SVGMat.043"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_43.geometry}
          material={materials["SVGMat.044"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_44.geometry}
          material={materials["SVGMat.045"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_45.geometry}
          material={materials["SVGMat.046"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_46.geometry}
          material={materials["SVGMat.047"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_47.geometry}
          material={materials["SVGMat.048"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_48.geometry}
          material={materials["SVGMat.049"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_49.geometry}
          material={materials["SVGMat.050"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_50.geometry}
          material={materials["SVGMat.051"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_51.geometry}
          material={materials["SVGMat.052"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_52.geometry}
          material={materials["SVGMat.053"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_53.geometry}
          material={materials["SVGMat.054"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_54.geometry}
          material={materials["SVGMat.055"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_55.geometry}
          material={materials["SVGMat.056"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_56.geometry}
          material={materials["SVGMat.057"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_57.geometry}
          material={materials["SVGMat.058"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_58.geometry}
          material={materials["SVGMat.059"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_59.geometry}
          material={materials["SVGMat.060"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_60.geometry}
          material={materials["SVGMat.061"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_61.geometry}
          material={materials["SVGMat.062"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_62.geometry}
          material={materials["SVGMat.063"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_63.geometry}
          material={materials["SVGMat.064"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_64.geometry}
          material={materials["SVGMat.065"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Curve036_65.geometry}
          material={materials["SVGMat.066"]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/assets/ValphetaTestThree.glb");

