import React from 'react'
import styles from './componentstyles/AboutUs.module.css'
// '../../styles/AboutUs.module.css'
import { lazy,Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import { Center, Environment, OrbitControls } from '@react-three/drei'
import { ValphetaTestThree } from './THREEDCOMPONENTS/ValphetaLogoThree'
// import ArGlasses  from './THREEDCOMPONENTS/ArGlasses'

// const ArGlasses = lazy(() => import("./THREEDCOMPONENTS/ArGlasses"));

export default function AboutUs() {
  return (
    <>
    <div className={styles.aboutusscreencontainer}>
    <h1 className={styles.strokedaboutus}>About Us</h1>
    <div className={styles.threedtlogocontainer}>
          {/* <Canvas style={{backgroundColor:"black"}}> */}
          <Canvas style={{paddingTop:"0px" ,width: "100vw", height: "50vh"}}>
            <Center/>
        <ambientLight intensity={0.1} />
  <directionalLight color="white" position={[0, 0, 1]} />
        <Suspense fallback={null}>
          <ValphetaTestThree/>
          <OrbitControls/>
          {/* <ArGlasses /> */}
          {/* <Environment preset="sunset" background /> */}
        </Suspense>
      </Canvas>
         
      </div>
    <h1 className={styles.revolutioncoming}>#Born To Revolutionize Mobility</h1>
    <h1 className={styles.attv}>What does Valpheta mean?</h1>
    <h1 className={styles.attv}>We are combination of</h1>
    <h1 className={styles.heretomake}>V</h1>
    <h1 className={styles.actualabout}>&quot;We ❤️ Vision&quot;</h1>
    <h1 className={styles.actualabout}>&quot;We ❤️ Victory&quot;</h1>
    <h1 className={styles.actualabout}>&quot;We ❤️ Values&quot;</h1>
    <h1 className={styles.heretomake}>Alpha</h1>
    <h1 className={styles.actualabout}>&quot;Alpha waves are brain waves that induce feelings of calm, increase creativity, and enhance your ability to absorb new information. &quot;</h1>
    <h1 className={styles.actualabout}>&quot;We are building systems that are calm,creative,reliable and emerge Victorious no matter where we use &quot;</h1>
    <h1 className={styles.heretomake}>Beta</h1>
    <h1 className={styles.actualabout}>&quot;Beta waves are high-frequency, low-amplitude brain waves that are commonly observed in an awaken state. They are involved in conscious thought and logical thinking, and tend to have a stimulating effect. Having the right amount of beta waves allows us to focus.&quot;</h1>
    <h1 className={styles.actualabout}>&quot;We are Building Systems that are Focused on things needed to be done.Continiously Conscious and Logically thinking To Improve everyones Experience&quot;</h1>
    <h1 className={styles.lasttagline}>&quot;We are Calm,Creative,Focused company with a very strong Vision ,deep rooted values and strong desire for Victory&quot;</h1>
    {/* <h1 className={styles.actualabout}>&quot;We are doing what is needed to be done! &quot;</h1>
    <h1 className={styles.actualabout}>&quot;The Immersive Expereience that we are building will give an unparalled expereinece to our end Users&quot;</h1> */}
    <h1 className={styles.lasttagline}>&quot;We are making Mobility remarkable,lovable and enjoyable.&quot;</h1>
    </div>
    </>
  )
}
