import React from 'react'
// import Image from 'next/image'
import styles from './componentstyles/Vision.module.css'
// import VrHeadset  from './THREEDCOMPONENTS/VrHeadset'
import { lazy,Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
// import { Environment } from '@react-three/drei'

// const VrHeadset = lazy(() => import("./THREEDCOMPONENTS/VrHeadset"));

// export default function Vison() {
//   return (
//     <>
//     <div classNameName={styles.visionscreencontainer}>
//     <h1 classNameName={styles.vision}>Vision</h1>
//     <h1 classNameName={styles.welcometo}>&quot;At Threed Verse Our Vision is&quot; </h1>
//     <h1 classNameName={styles.welcometo}>&quot;To Build an Immersive Internet and the set of Next Generation Technology&quot;</h1>
//     <h1 classNameName={styles.welcometo}>That Powers All Of Those Increadible and amazing Experieneces Awaiting Us</h1>
//     <h1 classNameName={styles.welcometo}>&quot;The Immersive Internet&quot;</h1>
//     <h1 classNameName={styles.welcometo}>&quot;We are Gonna Make It!&quot;</h1>
//     <h1 classNameName={styles.welcometo}>&quot;Yes Confident Enough!&quot;</h1>
//     <h1 classNameName={styles.welcometo}>&quot;Actually Very Very Confident&quot;</h1>
//     </div>
//     </>
//   )
// }

// export default function Vison() {
//   return (
//     <>
//     <div classNameName={styles.visionscreencontainer}>
//     <h1 classNameName={styles.vision}>Vision</h1>
//     <h1 classNameName={styles.attv}>&quot;At Threed Verse Our Vision is&quot; </h1>
//     <h1 classNameName={styles.visionqoute}>&quot;To Build an Immersive Internet and the set of Next Generation Technology&quot;</h1>
//     <h1 classNameName={styles.visionqoute}>That Powers All Of Those Increadible and amazing Experiences Awaiting Us</h1>
//     <h1 classNameName={styles.immersiveinternet}>&quot;The Immersive Internet&quot;</h1>
//     <h1 classNameName={styles.wegonnamakeit}>&quot;We are Gonna Make It!&quot;</h1>
//     <h1 classNameName={styles.confidentenough}>&quot;Yes Confident Enough!&quot;</h1>
//     <h1 classNameName={styles.veryveryconfident}>&quot;Actually Very Very Confident&quot;</h1>
//     </div>
//     </>
//   )
// }


export default function Vison() {
  return (
    <>
    <div className={styles.visionscreencontainer}>
    <h1 className={styles.strokedvision}>Vision</h1>
    <h1 className={styles.attv}>&quot;At Valpheta Our Vision is&quot; </h1>
    <h1 className={styles.visionqoute}>&quot;To Make Mobility In This World Safe For Everyone&quot;</h1>
    {/* <section className={styles.header}>
  <div className={styles.titlewrapper}>
    <h1 className={styles.sweettitle}>
   <span className={styles.sweet} title='Sweet'>Immersive</span>
    </h1>
    <h1 className={styles.sweettitle}>
   <span className={styles.sweet} title='Sweet'>Internet</span>
    </h1>
    </div>
</section>
    <div className={styles.threedtlogocontainer}>
          <Canvas style={{paddingTop:"0px" ,width: "100vw", height: "50vh"}}>
        <ambientLight intensity={0.1} />
  <directionalLight color="white" position={[0, 0, 5]} />
        <Suspense fallback={null}>
        </Suspense>
      </Canvas>
          </div>
    <h1 className={styles.wegonnamakeit}>&quot;We are Gonna Make It!&quot;</h1>
    <h1 className={styles.confidentenough}>&quot;Yes Confident Enough!&quot;</h1>
    <h1 className={styles.veryveryconfident}>&quot;Actually Very Very Confident&quot;</h1> */}
    </div>
    </>
  )
}
